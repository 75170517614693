<template>
  <div class="tenant-container">
    <div class="tenant-header">
      <h3 class="header-text">All Requests</h3>
    </div>
    <div class="text-center mt-5" v-if="loading">
      <!-- Loader component -->
      <Loader />
    </div>
    <div v-else-if="requestData.length == 0" class="no-items-available">
      <p>No request yet</p>
    </div>
    <ul v-else role="list" class="">
      <li v-for="(smartGuarantor, index) in requestData" :key="index" class="frame-container">
        <div @click="showModalForSmartGuarantor(smartGuarantor)"
          :class="getFrameClass(smartGuarantor.status) + '-status frame'">
          <div class="frame-content">
            <div class="frame-top">
              <span class="name-text">
                {{
                smartGuarantor.firstname + " " + smartGuarantor.lastname}}
              </span>

              <div :class="getFrameBadgeClass(smartGuarantor.status)">
                <div :class="getFrameBadgeBaseClass(smartGuarantor.status)">
                  <span :class="{
                      'pending-status': smartGuarantor.status === 'pending',
                      'accepted-status': smartGuarantor.status === 'accepted',
                      'rejected-status': smartGuarantor.status === 'rejected',
                    }" class="status-indicator">
                    {{ smartGuarantor.status }}
                  </span>
                </div>
              </div>
            </div>
            <p class="frame-location">{{ smartGuarantor.location }}</p>
            <div class="frame-table-cell" v-if="smartGuarantor.pi.PI != null">
              <div class="frame-progress-bar">
                <span class="frame-progress-text">PI</span>
                <div class="frame-progress-bar-inner">

                  <div class="frame-progress-background"></div>
                  <div v-if="smartGuarantor.pi.PI != null" class="frame-progress-foreground" :style="{ width: smartGuarantor.pi.PI + '%' }"></div>

                </div>
                <span class="frame-progress-label">{{ smartGuarantor.pi.PI }}%</span>
              </div>
            </div>
          </div>
          <!-- <hr> -->
          <span class="frame-view-details">View details</span>
        </div>
      </li>
    </ul>

    <!-- :req_smart_id="selectedSmartGuarantor ? selectedSmartGuarantor.req_smart_id : ''" -->
    <GuarantorModal :showModal="showModal" :smartGuarantor="selectedSmartGuarantor"
      :firstname="selectedSmartGuarantor ? selectedSmartGuarantor.firstname : ''"
      :lastname="selectedSmartGuarantor ? selectedSmartGuarantor.lastname : ''"
      :location="selectedSmartGuarantor ? selectedSmartGuarantor.location : ''"
      :status="selectedSmartGuarantor ? selectedSmartGuarantor.status : ''" @close-modal="closeModal" />
  </div>
</template>

<script>
import GuarantorModal from "../components/GuarantorModal.vue";
import { requestForSmartGuarantors } from "../services/requestForSmartGuarantors";
import Loader from "./../components/Loader.vue";

export default {
  name: "TenantRequest",
  data() {
    return {
      loading: false,
      smartGuarantors: [],
      showModal: false,
      selectedSmartGuarantor: null,
      requestData: [],
    };
  },
  mounted() {

    this.fetchSmartGuarantors();
  },
  methods: {
    async fetchSmartGuarantors() {
      this.loading = true;
      try {
        const data = await requestForSmartGuarantors.fetchSmartGuarantors();
        // console.log("datatata", data);
        this.loading = false;
        // if (data && Array.isArray(data)) {
          if (data) {
            this.requestData = data;
          // Extract only the required properties from each object
          // const extractedData = data.map((entry) => {
          //   let location = entry.location;
          //   // Check if location has a com
          //   if (location && location.includes(",")) {
          //     location = location.split(",").slice(1).join(",").trim();
          //   } else {
          //     // If no comma, use the last 30 characters
          //     location = location
          //       ? location
          //       : "Address needs to be updated";
          //   }
          //   return {
          //     firstname:
          //       entry.firstname.charAt(0).toUpperCase() +
          //       entry.firstname.slice(1).toLowerCase(),
          //     lastname:
          //       entry.lastname.charAt(0).toUpperCase() +
          //       entry.lastname.slice(1).toLowerCase(),
          //     status: entry.status,
          //     location,
          //     id: entry.id,
          //     req_smart_id: entry.req_smart_id,
          //     progressPercent: 60
          //   };
          // });
          // this.smartGuarantors = extractedData;
          // console.log(this.smartGuarantors)
        } else {
          console.error("Invalid data structure from API");
          this.loading = false;
        }
      } catch (error) {
        console.error("Error fetching smart guarantors:", error);
        this.loading = false;
      }
    },
    getFrameClass(status) {
      return `frame ${status.toLowerCase()}`;
    },
    getFrameBadgeClass(status) {
      return `frame-badge ${status.toLowerCase()}-badge`;
    },
    getFrameBadgeBaseClass(status) {
      return `frame-${status.toLowerCase()}-frame-badgebase`;
    },
    getFrameBadgeTextClass(status) {

      return `frame-${status.toLowerCase()}-text${this.getStatusNumber(status)}`;

    },

    showModalForSmartGuarantor(smartGuarantor) {
      this.selectedSmartGuarantor = smartGuarantor;
      this.showModal = true;
    },

    closeModal() {
      this.showModal = false;
    },
  },
  components: { GuarantorModal, Loader },
};
</script>

<style scoped>
.status-indicator {
  padding: 5px;
  border-radius: 12px;
}

.pending-status {
  background-color: rgba(245, 245, 247, 1);
  color: grey;
  display: flex;
  padding: 2px 8px;
  align-items: center;
  border-radius: 12px;
  justify-content: center;
  font-size: 14px;
  text-transform: capitalize;
}

.accepted-status {
  background-color: rgb(44, 135, 19);
  color: white;
  text-transform: capitalize;
  display: flex;
  padding: 2px 8px;
  align-items: center;
  border-radius: 16px;
  justify-content: center;
  font-size: 12px;

}

.rejected-status {
  background-color: red;
  color: white;
  font-size: 12px;
  border-radius: 16px;
  text-transform: capitalize;
}

.tenant-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  flex-direction: column;
}

.tenant-header {
  gap: 16px;
  width: 100%;
  height: auto;
  display: flex;
  padding: 20px;
  align-items: center;
  background-color: white;
  margin-top: 10px;
}

ul {
  list-style: none;
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 1.5rem;
  margin-top: 1.25rem;
  padding-right: 0.5rem;
}

@media (min-width: 768px) {
  ul {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (min-width: 1024px) {
  ul {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    padding-right: 0;
  }
}

.frame-container {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between
}

.frame {
  gap: 15px;
  width: 100%;
  height: auto;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  padding: 20px;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 1);
  min-height: 196px;
  cursor: pointer;
}

.frame-content {
  gap: 4px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}

.frame-top {
  gap: 4px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}

.name-text {
  color: #555C74;
  height: auto;
  flex-grow: 1;
  font-size: 16px;
  font-style: normal;
  text-align: left;
  font-family: Lato;
  font-weight: 400;
  line-height: 139.9999976158142%;
  font-stretch: normal;
  text-decoration: none;
}

.frame-location {
  color: #555C74;
  /* min-height: 50px;
  height: 100%; */
  height: auto;
  font-size: 16px;
  align-self: stretch;
  font-style: normal;
  text-align: left;
  font-family: Lato;
  font-weight: 400;
  line-height: 139.9999976158142%;
  font-stretch: normal;
  text-decoration: none;
  word-wrap: break-word;
  margin-top: 10px !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  /* width: 21rem; */
}

.frame-table-cell {
  gap: 12px;
  display: flex;
  padding: 8px 0;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  /* border-color: rgba(234, 236, 240, 1);
  border-style: solid;
  border-width: 0 0 1px; */
}

.frame-progress-text {
  color: black;
  margin-right: 10px;
  height: auto;
  font-size: 14px;
  font-style: Medium;
  text-align: left;
  font-family: Lato;
  font-weight: 500;
  line-height: 20px;
  font-stretch: normal;
  text-decoration: none;
}

.frame-progress-bar {
  display: flex;
  align-items: center;
  width: 100%;
}


.frame-progress-bar-inner {
  width: 100%;
  display: flex;
  height: 10px;
  background-color: #eee;
  border-radius: 5px;
  position: relative;
  flex-grow: 1;
}

.frame-progress-background {
  height: 100%;
  background-color: #ddd; /* Inactive color */
  border-radius: 5px;
}

.frame-progress-foreground {
  height: 100%;
  background-color: blue; /* Active color */
  border-radius: 5px;
  position: absolute;
  top: 0;
  left: 0;
}

.frame-progress-label {
  margin-left: 10px;
}

.frame-progress-background {
  height: 8px;
  float: left;
  border-radius: 4px;
  background-color: blue;
}

.frame-progress-indicator {
  top: 0px;
  left: 0px;
  width: 153px;
  height: 8px;
  position: absolute;
  border-radius: 4px;
}

.frame-progress-label {
  color: black;
  height: auto;
  font-size: 14px;
  font-style: Medium;
  text-align: left;
  font-family: Lato;
  font-weight: 500;
  line-height: 20px;
  font-stretch: normal;
  text-decoration: none;
}

.frame-view-details {
  color: rgba(0, 51, 233, 1);
  height: auto;
  font-size: 16px;
  align-self: stretch;
  text-align: left;
  font-family: Lato;
  font-weight: 500;
  line-height: 20px;
  font-stretch: normal;
  text-decoration: none;
  cursor: pointer;
  padding-top: 15px;
  border-top: 1px solid #EAECF0;
}

.frame-pending {
  background-color: rgb(239, 230, 230);
}

.frame-accepted {
  background-color: rgb(46, 147, 18);
}

.frame-rejected {
  background-color: red;
}

/* Pending */
.frame1000004255-badge {
  display: flex;
  align-items: flex-start;
}

.frame1000004255-frame-badgebase {
  display: flex;
  padding: 2px 8px;
  align-items: center;
  border-radius: 14px;
  justify-content: center;
  background-color: rgba(245, 245, 247, 1);
}

.frame1000004255-text02 {
  color: rgba(85, 92, 116, 1);
  height: auto;
  font-size: 10px;
  font-style: Medium;
  text-align: center;
  font-family: Lato;
  font-weight: 300;
  line-height: 18px;
  font-stretch: normal;
}

/* Accepted */
.frame1000004256-badge1 {
  display: flex;
  align-items: flex-start;
}

.frame1000004256-frame-badgebase1 {
  display: flex;
  padding: 2px 8px;
  align-items: center;
  border-radius: 16px;
  justify-content: center;
  background-color: rgb(44, 135, 19);
}

.frame1000004256-text14 {
  color: rgba(42, 195, 0, 1);
  height: auto;
  font-size: 10px;
  font-style: Medium;
  text-align: center;
  font-family: Lato;
  font-weight: 500;
  line-height: 18px;
  font-stretch: normal;
  text-decoration: none;
}

/* Rejected */
.frame1000004257-badge2 {
  display: flex;
  align-items: flex-start;
}

.frame1000004257-frame-badgebase2 {
  display: flex;
  padding: 2px 8px;
  align-items: center;
  border-radius: 16px;
  justify-content: center;
  background-color: rgb(181, 50, 78);
}

.frame1000004257-text26 {
  color: white;
  height: auto;
  font-size: 10px;
  text-align: center;
  font-family: Lato;
  font-weight: 500;
  line-height: 18px;
  font-stretch: normal;
  text-decoration: none;
}
.no-items-available{
  align-items: center;
  justify-content: center;
  flex: content;
  padding: 20px;
  color: #555C74;
  text-align: center;
}
</style>
