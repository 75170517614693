<template>

    <div>
        <div class="circular-progress" :style="{ width: size + 'px', height: size + 'px' }">
            <svg class="progress-ring" viewBox="0 0 100 100">
                <circle class="progress-ring-circle" :cx="center" :cy="center" :r="radius" :stroke-width="strokeWidth"
                    :style="{ strokeDasharray: circumference, strokeDashoffset: progressOffset, stroke: color }">
                </circle>
            </svg>
            <div class="progress-text">{{ percentage }}%</div>
        </div>
        <div class="name-text">{{ name }}</div>
    </div>
</template>

<script>
export default {
    props: {
        percentage: {
            type: Number,
            required: true,
            validator: value => value >= 0 && value <= 100
        },
        color: {
            type: String,
            default: 'green'
        },
        size: {
            type: Number,
            default: 75
        },
        name:{
            type: String,
            // default: 'PI'
        }
    },
    computed: {
        center() {
            return this.size / 2;
        },
        radius() {
            return this.size / 2 - this.strokeWidth / 2;
        },
        strokeWidth() {
            return this.size * 0.1;
        },
        circumference() {
            return 2 * Math.PI * this.radius;
        },
        progressOffset() {
            return this.circumference * (1 - this.percentage / 100);
        }
    }
};
</script>

<style scoped>
.circular-progress {
    position: relative;
}

.progress-ring {
    width: 90%;
    height: 90%;
}

.progress-ring-circle {
    fill: none;
    stroke-linecap: round;
}

.progress-text {
    position: absolute;
    top: 33%;
    left: 35%;
    transform: translate(-50%, -50%);
    font-size: 0.8em;
    color: #333;
}

.name-text {
  text-align: center;
  margin-top: -20px;
  margin-left: -20px;
}
</style>