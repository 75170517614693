<template>
  <transition name="modal-slide">
    <!-- <transition name="modal-fade"> -->
    <div class="popup-modal" v-if="showModal" @click.self="closeModal" ref="popupModal">
      <div class="modal-content">
        <div class="header-section">
          <div class="header-top">
            <h5 class="modal-title">Request Details</h5>
            <button @click="closeModal" class="close-button">X</button>
          </div>
          <p class="modal-description">
            This shelta user is requesting you as a guarantor for a <span style="text-transform: uppercase; color: #FFB100; font-weight: 400;">{{ smartGuarantor.type.replace(/_/g, ' ') }} </span>application
          </p>
        </div>
        <div class="modal-section-content">
          <h5 class="modal-section-title">Basic Information</h5>
          <p class="modal-name">{{ firstname }} {{ lastname }}</p>
          <div style="box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; padding: 10px; border-radius: 4px;">
            <p class="modal-info">Location: {{ location }}</p>
            <p class="modal-info">Status: {{ status.charAt(0).toUpperCase() + status.slice(1) }}</p>
            <p class="modal-info">Request type: {{ smartGuarantor.type.replace(/_/g, ' ') }}</p>
          </div>
          <hr class="modal-hr" />
          <h5 class="modal-section-title" v-if="smartGuarantor.type == 'gap_finance'">Financial Information</h5>
          <div class="financial-info" v-if="smartGuarantor.type == 'gap_finance'">
            <CircularProgress :percentage="smartGuarantor.pi.PI" color="green" name="PI" />
            <CircularProgress :percentage="smartGuarantor.pi.APF" color="red" name="APF" />
            <CircularProgress :percentage="smartGuarantor.pi.CS" color="orange" name="CS" />

          </div>
          <hr class="modal-hr" v-if="smartGuarantor.type == 'gap_finance'"/>
          <h5 class="modal-section-title">Transaction history</h5>
          <p class="modal-info">Successful transaction</p>
          <ul class="transaction-list">
            <li v-if="smartGuarantor.transactions_history">Rent Finance: {{ smartGuarantor.transactions_history.success_rent_finance }}</li>
            <li v-if="smartGuarantor.transactions_history">Gap Finance: {{ smartGuarantor.transactions_history.success_gap_finance }}</li>
          </ul>
          <hr class="modal-hr" />
          <h5 class="modal-section-title">Failed transaction</h5>
          <ul class="transaction-list">
            <li v-if="smartGuarantor.transactions_history">Rent Finance: {{ smartGuarantor.transactions_history.failed_rent_finance }}</li>
            <li v-if="smartGuarantor.transactions_history">Gap Finance: {{ smartGuarantor.transactions_history.failed_gap_finance }}</li>
          </ul>
          <hr class="modal-hr" />
          <div class="modal-buttons" style="margin-bottom: 15px;" v-if="status == 'pending'">
            <button @click="requestAction('rejected')" :disabled="smartGuarantor.status != 'pending' || requestProcessing" class="reject-button">
              {{ requestProcessing && requestStatus == 'rejected' ? "Processing..." : "Reject" }}
            </button>
            <button @click="requestAction('accepted')" :disabled="smartGuarantor.status != 'pending' || requestProcessing" class="accept-button">
              {{ requestProcessing && requestStatus == 'accepted' ? "Processing..." : "Accept" }}
            </button>
            <!-- <button @click="showConfirmationModal = true" class="accept-button">Accept</button> -->

            <!-- Include ConfirmationModal component -->
            <!-- <ConfirmationModal :showConfirmationModal="showConfirmationModal" @cancel="cancelAccept"
              @confirm="acceptRequest" /> -->

          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
// import { updateRequestForSmartGuarantorStatus } from "../services/updateRequestForSmartGurantorStatus";
import CircularProgress from "../components/widgets/CircularProgress.vue";
// import ConfirmationModal from "../components/modals/ConfirmationModal.vue";
import points from "../services/points";

export default {
  components: {
    CircularProgress,
    // ConfirmationModal
  },

  props: {
    showModal: Boolean,
    smartGuarantor: Object,
    firstname: String,
    lastname: String,
    location: String,
    status: String,
    // req_smart_id: Number
  },
  data() {
    return {
      // showConfirmationModal: false,
      requestProcessing: false,
      requestStatus: "",
      PI: "",
    };
  },
  methods: {
    closeModal() {
      this.$emit("close-modal");
    },

    // rejectRequest() {
    //   this.updateRequestStatus("rejected");
    //   this.closeModal();
    // },

    requestAction(action) {
      this.requestStatus = action;
      this.requestProcessing = true;
      let body = {
        status: action,
        requestId: this.smartGuarantor.id
      };

      body = JSON.stringify(body);

      points.acceptRequest(body).then(data => {
        this.requestProcessing = false;
        // console.log("Requesttttttt:", data);
        if (data.success) {
          this.$toast.success("success");
          this.closeModal();
          window.location.reload();
        } else if (data.error) {
          this.$toast.error(data.error);
        }
        else {
          this.$toast.error("Error occurred while processing");
        }
      })
      .catch(() => {
        this.requestProcessing = false;
        this.$toast.error("Error, please check your network and refresh the page");
      })
      .finally(() => {
        this.requestProcessing = false;
      });
    },
    // cancelAccept() {
    //   this.showConfirmationModal = false;
    // },

    // acceptRequest() {
    //   this.updateRequestStatus("accepted");
    //   this.closeModal();
    // },

    // acceptRequest() {
    //   this.$emit('show-confirmation-modal');
    // },

    cancelAccept() {
      // Hide the confirmation modal when canceled
      this.showConfirmationModal = false;
    },

    // async updateRequestStatus(newStatus) {
    //   console.log(this.req_smart_id)
    //   try {
    //     await updateRequestForSmartGuarantorStatus.updateStatus({
    //       requestId: this.smartGuarantor.req_smart_id,
    //       status: newStatus,

    //     });
    //     this.closeModal();
    //     this.refreshPage()
    //   } catch (error) {
    //     console.error("Error updating request status:", error);
    //   }
    // },

    refreshPage() {
      this.$router.go(0)
    }
  },

  beforeRouteUpdate(to, from, next) {
    next(vm => {
      vm.refreshPage(); // Call the refresh method when the route updates
    });
  }
};
</script>

<style scoped>
/* .popup-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  background: rgba(0, 0, 0, 0.5);
  width: 90%;
  max-width: 400px;
} */
/* body * {
  margin: 0 !important;
} */
.popup-modal {
  /* Modal styles */
  position: fixed;
  z-index: 1000;
  background: rgba(245, 243, 243, 0.5);
  width: 90%;
  max-width: 400px;
  right: 0;
  top: 0;
  border-radius: 10px;
  box-shadow: 0 0 0px rgba(221, 217, 217, 0.5);
  overflow-y: scroll;
  height: 100%;
}


/* Animation styles */
.modal-slide-enter-active {
  animation: modalSlideIn 0.5s;
}

.modal-slide-leave-active {
  animation: modalSlideOut 0.5s;
}

@keyframes modalSlideIn {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0);
  }
}

@keyframes modalSlideOut {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(100%);
  }
}

/* Media queries */
@media (max-width: 768px) {
  .popup-modal {
    /* Modal styles for mobile view */
    right: 100;
    top: 0;
    /* max-width: none; */
    width: 80%;
    /* height: 40%; */
    height: 100%;
    border-radius: 0;
  }

  /* Additional animation for mobile view */
  .modal-slide-enter-active {
    animation: modalSlideInMobile 0.5s;
  }

  .modal-slide-leave-active {
    animation: modalSlideOutMobile 0.5s;
  }

  @keyframes modalSlideInMobile {
    from {
      transform: translateY(100%);
    }

    to {
      transform: translateY(0);
    }
  }

  @keyframes modalSlideOutMobile {
    from {
      transform: translateY(0);
    }

    to {
      transform: translateY(100%);
    }
  }
}

.modal-content {
  background-color: white;
  /* padding: 20px; */
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.header-section {
  background-color: blue;
  color: white;
  padding: 10px;
}

.header-top {
  display: flex;
  justify-content: space-between;
  font-family: Lato;
  align-items: center;
}

.modal-title {
  padding-left: 10px;
  padding-top: 10px;
  font-size: 16px;
  font-family: Lato;
}

.modal-name{
  font-size: 16px;
  color: #555C74;font-weight: 600;
  padding-bottom: 8px;
  font-family: Lato;
}

.close-button {
  background-color: transparent;
  text-align: center;
  color: white;
  border: none;
  margin-right: 4px;
  background: #1b20c7;
  border-radius: 50px;
  height: 30px;
  width: 30px;
}

.modal-description {
  padding: 10px;
  font-size: 12px;
  /* font-family: Lato; */
  line-height: 24px;
}

.modal-section-title {
  /* font-size: 14px;
  font-weight: 500; */
  color: #555C74;

  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;

}

.modal-section-content {
  gap: 8px;
  padding: 16px;
  font-size: 14px;
}

.modal-info {
  color: #555C74;
  text-transform: capitalize;
  font-family: Lato;
  line-height: 24px;
}

.modal-hr {
 /* margin-top: 5px; */
}

.financial-info {
  display: flex;
}

.financial-item {}

.transaction-list {
  padding: 10px 20px;
}

.modal-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 10px;
}

.reject-button,
.accept-button {
  gap: 8px;
  display: flex;
  padding: 10px 18px;
  border: none;
  border-radius: 5px;
  justify-content: center;
  cursor: pointer;
}

.reject-button {
  background-color: rgba(235, 0, 0, 1);
  color: white;
}

.accept-button {
  background-color: rgba(0, 51, 233, 1);
  color: white;
}

.unused {
  box-shadow: rgb(85, 91, 255) 0px 0px 0px 3px, rgb(31, 193, 27) 0px 0px 0px 6px, rgb(255, 217, 19) 0px 0px 0px 9px, rgb(255, 156, 85) 0px 0px 0px 12px, rgb(255, 85, 85) 0px 0px 0px 15px;
}
</style>
