
import client from "./requestclient";

export const requestForSmartGuarantors = {
  fetchSmartGuarantors: async () => {
    try {
      const response = await client({ URL_PATH: "requestforsmartguarantors", method: "GET" });
      return response.data; 
    } catch (error) {
      console.error("Error fetching smart guarantors:", error);
      throw error; 
    }
  },
};

